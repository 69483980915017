
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import OuterInputComplete from '@/components/organisms/dms/OuterInputComplete/OuterInputComplete.vue'
import Breadcrumb from '@/components/organisms/dms/common/Breadcrumb.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
export default defineComponent({
  components: {
    OuterInputComplete,
    Breadcrumb,
    DssIcon,
  },
  setup () {
    const router = useRouter()
    const list = [
      { text: 'ファイルをアップロード', numFlag: true },
      { text: '完了', numFlag: false },
    ]
    return {
      router,
      list,

      async toTop () {
        await router.push('/dms/outer')
      },
    }
  },
})
